import React from "react"
import { graphql } from "gatsby"

// The component is going to randomly select a size...
//
// > Then it'll try to get the Hero Image that has the same size value...
//
// > If it has the image, then it will render the component using that image (coming from Cloudinary with the standard cropping dimensions applied for the selected size)
//
// > If it does not have the image, it will render the component using the initial (default) Hero Image (coming from Cloudinary with the standard cropping dimensions applied for the selected size with the focal point and gravity ("g_xy_center") parameters getting applied as a secondary transform)
// Aside: Cloudinary allows you to apply multiple transforms to a given image.

const Thumbnail = ({ size, url }) => {
  return (
    <>
      <img className={size} src={url} />
    </>
  )
}
export default Thumbnail

import React, { useEffect } from "react"
import { graphql, Link, withPrefix } from "gatsby"

import PageHeader from "../components/pageHeader"
import Layout from "../components/layout/siteLayout"
import SEO from "../components/layout/seo"
import Thumbnail from "../components/thumbnail"
import { resizeMasonryItem, resizeAllMasonryItems } from "../utils"
import imagesLoaded from "imagesloaded"

import "./open-mic.scss"

const OpenMicPage = ({ data, location }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onload = resizeAllMasonryItems()
      window.addEventListener("resize", resizeAllMasonryItems())
    }
    const resizeInstance = instance => {
      const item = instance.elements[0]
      resizeMasonryItem(item)
    }
    const allItems = document.getElementsByClassName("open-mic__post-container")
    for (let x = 0; x < allItems.length; x++) {
      imagesLoaded(allItems[x], resizeInstance)
    }
  })
  const {
    entries,
    openMicContent: { subtitle },
  } = data.craft
  const masonryElements = entries.map(entry => {
    const { id, slug, title, heroImage, subtitle } = entry
    const addSpace = () => {
      if (title.includes(":")) {
        return title.split(":").join(": ")
      }
      return title
    }
    return (
      <div className="open-mic open-mic__post-container" key={id}>
        <Link
          className="post"
          to={`/open-mic/${slug}`}
          style={{
            display: "flex",
          }}
        >
          {heroImage.length > 0 && (
            <div
              className={`open-mic open-mic__post-image ${
                heroImage[0].heroImageSize
              }`}
            >
              <Thumbnail
                size={heroImage[0].heroImageSize}
                url={`https://res.cloudinary.com/amplifiedbydesign/image/upload/x_${Math.floor(
                  heroImage[0].focalPoint.x *
                    heroImage[0].width *
                    (225 / heroImage[0].height)
                )},y_${Math.floor(
                  heroImage[0].focalPoint.y *
                    heroImage[0].height *
                    (225 / heroImage[0].height)
                )}/${heroImage[0].filename}`}
              />
            </div>
          )}
          <div className="open-mic open-mic__post-details">
            <h3>{addSpace()}</h3>
            <p>{subtitle}</p>
          </div>
        </Link>
      </div>
    )
  })
  return (
    <Layout location={location}>
      <SEO title="Open Mic" location={location} />
      <PageHeader
        className="open-mic-header"
        style={{
          backgroundImage: `url(${withPrefix(
            "/images/open-mic/bg_openMic.png"
          )})`,
        }}
      >
        <div className="heading__subtext">
          <img
            src={`${withPrefix("/images/open-mic/open_mic-logo.svg")}`}
            alt=""
          />
          <p>{subtitle}</p>
        </div>
      </PageHeader>
      <div className="open-mic open-mic__posts">
        {entries.length > 0 && <>{masonryElements}</>}
      </div>
    </Layout>
  )
}

export const getOpenMicPostsQuery = graphql`
  query {
    craft {
      openMicContent: entry(section: openMic) {
        ... on Craft_OpenMic {
          id
          slug
          title
          subtitle
        }
      }
      entries(section: [openMicPosts]) {
        ... on Craft_OpenMicPosts {
          id
          slug
          title
          subtitle
          postDate
          heroImage {
            ... on Craft_HeroVolume {
              filename
              focalPoint {
                x
                y
              }
              height
              title
              width
              heroImageSize
              heroImageSizeModifier
            }
          }
        }
      }
    }
  }
`

export default OpenMicPage
